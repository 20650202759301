import React from "react"
import s from 'styled-components'

import Post from '../components/post'
import Layout from "../components/layout"
import { Text } from "../data/typography"
const fatherText = (
  <Text>
    시조 고을나왕(始祖 髙乙那王) – 탐라국왕 고을나(髙乙那)<br />

    제주고씨(濟州髙氏) 시조 고을나(髙乙那)왕 탁라국[탁라국(乇羅國) : 탐라국(耽羅國)]을 세우고 즉위함.[탐라국왕세기(耽羅國王世紀)]<br />

    영주(瀛洲) 군장(君長)으로서 천여세를 사셨다.<br />

    한라산 북쪽에 있는 삼성혈에서 삼신인이 솟아났는데, 첫째 분은 고을나(髙乙那)요, 다음 분은 양을나(良乙那)요, 셋째 분은 부을나(夫乙那)이시다.<br />

    세분은 돌을 세우고 활을 쏘아 살 자리를 택하고 북두칠성을 우러러보아 삼도리(三徒里)로 나누어 도읍을 정하고 국호를 탁라라 하였다. 영주지에 의하면 구백년 뒤에 이르러 온 나라 인심이 고씨(髙氏)에게 돌아와서 고씨로 임금을 삼았다.<br />

    당시의 년대를 중국의 제왕기에 미루어 보건대 천황씨와 지황씨는 각각 일만팔천세로 삼백회 갑자를 살았고 인황씨는 사만오천육백세를 살았다. 그뒤에 유소씨(有巢氏) 나무를 얽어서 집 짓는 법을 가르치고, 수인씨(燧人氏)는 불을 일구어 불 쓰는 법을 알리고, 복희씨伏羲氏)는 글을 발명하여 말을 글자로 나타내었고, 신농씨(神農氏)는 농사 짓는 법을 가르쳤고, 황제씨(皇帝氏)에 이르러서는 별을 보아 갑자력상(甲子曆象)을 만들어서 인월(寅月)로 한해의 첫 달을 삼고 보정신책(寶鼎神策)으로써 해와 달 사이의 부족을 메우기 위한 윤월(閏月)을 알아내어 한해를 채웠다.<br />

    고씨 구백년의 십오회 갑자는 당요(唐堯)의 갑진(甲辰)과 단군(檀君)의 무진(戊辰)과 같은 때로 이때에 임금이 된 것이다. 대개 신인은 하늘에서 명을 받고 땅에서 영(靈)을 길러 이기(理氣)로 화생(化生)하여 덕(德)을 이룬 것이다.<br />

    주역(周易)에 말하기를 하늘과 땅의 크낙한 덕이 곧 생이요, 하늘을 아버지로 땅 어머니로 섬김으로써 인간의 존귀함이 비롯된다고 하였다. 계사(繫辭)에는 하출도(河出圖) 락출서(洛出書)하였으므로 성인이 모방하여 변화를 일으키니 천지가 위치를 정하고 산과 못이 기운을 통함에 따라 우뢰와 바람과 물과 불이 순하게 가기도 하고 거슬러 오기도 하여 여러 가지 형태로 조화되고 하나가 합하여져서 한 태극을 이루고 음양이 생하여 거기서 충화된 기운이 생명의 형용으로 나타나서 각각 성명을 정한다 하였다.<br />

    태현진(太玄眞) 일경(一經)에는 근본도 없고 위도 없어 홀로 능히 만물의 시초가 되는 것이기 때문에 가장 존귀한 태을도군(太乙道君)이요, 근본도 기원(起源) 없는 원수(元首)로서 모든 유형의 근원(根源)이 된다 하였고, 명위경주(明威經註)에는 태허(太虛)중의 현묘(玄妙)한 이치(理致)는 정도(正道)의 사용이 된다 하였으며 단간묵록경(丹簡墨錄經)에는 진일(眞一)이나 태일(太一)은 곧 무형(無形)을 이름함이라 하였다.<br />

    이아[爾雅:周公(주공)]이 지은 글)에 하늘을 해석하기를 태세(太歲)가 을(乙)에 있는 것을 전몽이라 하고 월(月)이 을(乙)에 있는 것을 귤률(橘律)이라 한다 하였고, 력지(曆誌)에는 천지가 싹트는 기운이 을(乙)에서 발달 한다하고, 태을수(太乙數)에는 임금자리가 태을(太乙)이고 오복(五福)의 상징이 태을(太乙)이다.<br />

    최초 만상(萬象)의 이치(理致)는 하나에서 낳아 太(태)가 되고 하나가 변하여 태을원조(太乙元祖)가 되는 원리(原理)를 취하여 세(歲)의 차례를 태을궁(太乙宮)에서부터 시작된 해를 계산하여 상원갑자(上元甲子)라 한다 하였다. 고을나(髙乙那)왕의 배위(配位)는 벽랑국왕(碧浪國王)의 장녀로 옥함(玉函)을 타고 바다 동쪽에서 떠내려 왔다 하였는데 일설에 의하면 벽계국(碧溪國)은 일동(日東) 부상계(扶桑界)에 있는데 본시 동천국(東天國)라 하며 산해경(山海經)에서 말하는 천독국(天毒國)이 바로 이 나라[영주지瀛洲誌)에서]라 하였다.<br />

    [출처] 고씨 시조 고을나왕(始祖 髙乙那王) – 탐라국왕 고을나(髙乙那) 영주(瀛洲) 군장(君長)으로서 천여세를 사셨다 (경주이씨(慶州李氏)시조(始祖)표암공(瓢巖公)이알평(李謁平))<br />
  </Text>
)
const FatherPage = () => (
  <Layout pageTitle={'역 사'} pageSubtitle={'시조 고을나왕'}>
    <Post imageUrl={'/고을나왕.jpg'} text={fatherText} />
  </Layout>
)

export default FatherPage
